import AOS from 'aos';

export function worksIdxAosFunc() {
  AOS.init();
  AOS.init({
    offset: 0,
    easing: 'ease-in',
    duration: 600,
    once: true,
    //anchorPlacement:'center-center',
  });
}
