export function footerFunc() {
    var responsibleCheck
    var fNavDl;
    fNavDl = $('.fNavColumn nav dl dt');
    fNavDl.on('click', function () {
        responsibleCheck = $('#responsibleCheck').width();
        if (responsibleCheck == 2) {
            if ($(this).hasClass('spNotMove')) {
                $(this).next().slideToggle().prev().toggleClass('open');
                return false;
            }
        }
    });
}