// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  const scrTop = $('#scrTop');
  scrTop.on('click', function() {
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      1500,
      'easeInOutQuint'
    );
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight, position;
  const fixedNavHeight = $('#jsScrollFixed').outerHeight(true);

  $('a[href^="#"]:not(.notScroll)').each(function() {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click', function() {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top - headerHeight;
        // 追従アンカーナビが存在するときの調整
        if (fixedNavHeight) {
          position -= fixedNavHeight;
        }
        $('body,html').animate(
          {
            scrollTop: position,
          },
          1000,
          'easeInOutQuart'
        );
        return false;
      });
    }
  });
}
