var stickeyBox,
  stickeyBoxTop,
  scrResizecheck,
  setScr,
  headerHeight,
  varHeader,
  posRes,
  initialPos;
varHeader = $('#header');
export function scrAboutFunc() {
  if ($('.stickeyBox').length) {
    stickeyBox = $('.stickeyBox').offset().top;
    $(window).on('scroll', function() {
      setScr = $(window).scrollTop();
      scrResizecheck = $('#responsibleCheck').width();
      headerHeight = varHeader.outerHeight(true);
      posRes = setScr + headerHeight;
      if (scrResizecheck != 2) {
        if (stickeyBox > posRes) {
          $('.stickeyBox')
            .removeClass('isFixed')
            .css('top', 0);
        } else if (posRes > stickeyBox) {
          $('.stickeyBox')
            .addClass('isFixed')
            .css('top', headerHeight);
        }
      }
    });
  }
}

export function scrAnimationFunc() {
  var effect_pos = 0; // 画面下からどの位置でフェードさせるか(px)
  var effect_move = 50; // どのぐらい要素を動かすか(px)
  var effect_time = 500; // エフェクトの時間(ms) 1秒なら1000

  // フェードする前のcssを定義
  $('.scroll-fade').css({
    transform: 'translateY(' + effect_move + 'px)',
    transition: effect_time + 'ms',
  });

  // スクロールまたはロードするたびに実行
  $(window).on('scroll load', function() {
    var scroll_top = $(this).scrollTop();
    var scroll_btm = scroll_top + $(this).height();
    effect_pos = scroll_btm - effect_pos;

    // effect_posがthis_posを超えたとき、エフェクトが発動
    $('.scroll-fade').each(function() {
      var this_pos = $(this).offset().top;
      if (effect_pos > this_pos) {
        $(this).css({
          opacity: 1,
          transform: 'translateY(0)',
        });
      }
    });
  });
}

/**
 * ナビゲーションを追従させる関数です。
 * @export
 */
export function navScrollFixed() {
  // 使用するHTMLの定義
  const checkHtml =
    document.getElementById('jsScrollFixed') != null ? true : false;
  const $main = $('#jsScrollFixedBody'),
    $target = $('#jsScrollFixed'),
    $header = $('#header'),
    $flexHead = $('#flexScrJs'),
    $responsiveCheck = $('#responsibleCheck'),
    fixedClass = 'is-fixed';
  let targetHeight, targetPos, headerHeight, contentHeight, checkflexHead;
  let ticking = false;

  // #jsScrollFixedが無いときは動作させない
  if (checkHtml) {
    targetHeight = $target.outerHeight();
    targetPos = $target.offset().top + targetHeight;
    headerHeight = $header.outerHeight();
    contentHeight = targetPos + targetHeight + $main.outerHeight();
    checkflexHead = $flexHead.hasClass('scrOn');

    // スクロール監視をinit
    document.addEventListener('scroll', scrollFunc, { passive: true });
  }

  // スクロール時の処理
  function scrollFunc() {
    if (!ticking) {
      requestAnimationFrame(function() {
        ticking = false;
        let value = window.scrollY;

        // 追従エリア外：エリア内：エリアを超えた先で関数切り替え
        if (value > targetPos && value < contentHeight) {
          setFixed();
        } else if (value > contentHeight) {
          overFixed();
        } else {
          removeFixed();
        }
        // ヘッダーが固定されているかチェック
        checkflexHead = $flexHead.hasClass('scrOn');
      });
      ticking = true;
    }
  }

  // 追従エリア内のときの処理
  function setFixed() {
    $target.addClass(fixedClass);
    const resizecheck = $responsiveCheck.width();
    headerHeight = $header.outerHeight();

    // PCかつヘッダー固定時に固定値を付与
    // それ以外はヘッダーの高さを付与
    if (checkflexHead && resizecheck === 1) {
      $target.css('top', '64px');
    } else {
      $target.css('top', headerHeight);
    }

    // 追従ナビが抜けた分の高さをコンテンツに付与
    $main.css('padding-top', targetHeight);
  }

  // 追従エリアより手前の処理
  function removeFixed() {
    $target.removeClass(fixedClass).css('top', '0');
    $main.removeAttr('style');
  }

  // 追従エリアを越えたときの処理
  function overFixed() {
    // 慎ましやかに上に退避させる
    $target.css('top', -1 * headerHeight);
  }
}
