export function contactFunc() {
  var contactTop, sendBtn, errTxt;
  var errTxtArr = [];
  errTxtArr = {
    email: 'メールアドレスをご入力ください。',
    emailConf: '連絡用メールアドレス(再入力)をご入力ください。',
    emailValid: 'メールアドレスの形式でご入力ください。',
    emailMatched: 'メールアドレスが一致していません。',
    typeInquiry: 'お住まいの地域をご選択ください。',
    age: '年齢をご選択ください。',

  };
  errTxt = '.errTxt';
  sendBtn = $('.sendBtn');
  contactTop = $('#contactTop').length;
  if (contactTop) {

    /* ----------------------------------------------------------------------------------
    各項目をリアルタイムでチェック（個人フォーム）
    ---------------------------------------------------------------------------------- */
    // メールアドレス
    $("[name='email']").blur(function () {
      if ($(this).val() == '') {
        $(this).next(errTxt).find('p').text(errTxtArr.email);
      } else {
        // メールアドレスの形式であるかをチェック
        if (!$(this).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          $(this).next(errTxt).find('p').text(errTxtArr.emailValid);
        } else {
          $(this).next(errTxt).find('p').text(''); // 何もなければエラー記述を無しにする
        }
      }
    });

    // メールアドレス再入力
    $("[name='emailConf']").blur(function () {
      if ($(this).val() == '') {
        $(this).next(errTxt).find('p').text(errTxtArr.emailConf);
      } else {
        // メールアドレスの形式であるかをチェック
        if (!$(this).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          $(this).next(errTxt).find('p').text(errTxtArr.emailValid);
        } else if ($(this).val() != $("[name='email']").val()) {
          $(this).next(errTxt).find('p').text(errTxtArr.emailMatched);
        } else {
          $(this).next(errTxt).find('p').text(''); // 何もなければエラー記述を無しにする
        }
      }
    });

    // お住まいの地域
    $("[name='typeInquiry']").blur(function () {
      if ($(this).val() == '') {
        $(this).parent().next(errTxt).find('p').text(errTxtArr.typeInquiry);
      } else {
        $(this).parent().next(errTxt).find('p').text('');
      }
    });

    // 年齢
    $("[name='age']").blur(function () {
      if ($(this).val() == '') {
        $(this).parent().next(errTxt).find('p').text(errTxtArr.age);
      } else {
        $(this).parent().next(errTxt).find('p').text('');
      }
    });

    sendBtn.on('click', function () {
      /* ----------------------------------------------------------------------------------
		エラー箇所があればその場所までスクロール
		---------------------------------------------------------------------------------- */

    });
  }
}