export function modalImgFunc() {
  const $wrapper = $('#modal-img');
  const $dialog = $('#modal-content');
  const loadImg = '/asset/images/common/preloader.gif';

  $dialog.prepend(
    `<div id="modal-img-box"><img alt="" /><div class="modalLoader"><img src="${loadImg}" alt="Loading..."/></div></div>`
  );

  const $loader = $('#modal-content .modalLoader');

  if ($wrapper) {
    const $target = $('.modalJs');
    let urlImg, classActive;

    $target.on('click', function() {
      classActive = $(this).parents('.swiper-slide-active').length;

      if (classActive) {
        urlImg = $(this).data('fullimg');
        $wrapper.fadeIn(500).addClass('isActive');
        imageLoad(urlImg);
      }
    });

    $wrapper.on('click', function() {
      $(this)
        .fadeOut(200)
        .removeClass('isActive');
    });
  }

  function imageLoad(url) {
    $loader.css({ display: 'block' });
    let imgPreloader = new Image();
    imgPreloader.onload = function() {
      $('#modal-img-box')
        .children('img')
        .attr({ src: url });
      $loader.removeAttr('style');
    };

    imgPreloader.src = url;
  }
}
