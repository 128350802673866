import Swiper from 'swiper';
//トップページ　キービジュアルスライダー
export function idxTopSliderFunc() {
  var topSwiper, topOptions;
  topSwiper = undefined;
  topOptions = {
    loop: true,
    autoplay: {
      delay: 5000
    },
    speed: 800,
    effect: 'slide',
    normalizeSlideIndex: true,
    paginationClickable: true,
    navigation: {
      nextEl: '.topNext',
      prevEl: '.topPrev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  };
  //スワイプ実行処理
  function initTopSwiper() {
    topSwiper = new Swiper('#topSlide', topOptions);
  }
  initTopSwiper();
}

//モデルスライダー
export function idxModelSliderFunc() {
  var modelSwiper, modelOptions, responsibleCheck, timer, swiperWrapper, swiperSlide;
  var swiperSwitch;

  swiperSwitch = false;

  modelSwiper = undefined;
  timer = false;
  responsibleCheck = $('#responsibleCheck').width();
  swiperWrapper = $('#modelSlide').find('.swiper-wrapper');
  swiperSlide = $('#modelSlide').find('.swiper-wrapper').find('.swiper-slide');
  modelOptions = {
    loop: false,
    speed: 800,
    slidesPerView: 4,
    spaceBetween: 7,
    effect: 'slide',
    normalizeSlideIndex: true,
    paginationClickable: true,
    navigation: {
      nextEl: '.modelNext',
      prevEl: '.modelPrev',
    },
  };
  //スワイプ実行処理
  function initModelSwiper() {
    swiperSwitch = true;
    setTimeout(function () {
      modelSwiper = new Swiper('#modelSlide', modelOptions);
    }, 100);
  }

  //スワイプ終了処理
  function resetModelSwiper() {
    swiperSwitch = false;
    setTimeout(function () {
      for (var i = 0; i < swiperWrapper.length; i++) {
        swiperWrapper[i].removeAttribute('style');
      }
      for (var i = 0; i < swiperSlide.length; i++) {
        swiperSlide[i].removeAttribute('style');
      }
      modelSwiper.destroy(true, true);
      modelSwiper = undefined;
    }, 100);
  }

  //初回読み込み時
  if (responsibleCheck == 1) {
    initModelSwiper();
  } else {
    swiperSwitch = false;
  }
  
  //リサイズ処理
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      //リサイズ終了後の処理
      responsibleCheck = $('#responsibleCheck').width();
      if (responsibleCheck == 1 && swiperSwitch == false) {
        initModelSwiper();
      } else if (responsibleCheck == 2 && swiperSwitch == true) {
        resetModelSwiper();
      }
    }, 200);
  });
}

export function idxEventlSliderFunc() {
  var eventSwiper, eventOptions, responsibleCheck, timer, swiperWrapper, swiperSlide, initFlag, postMinesEventLayout;
  var swiperSwitch;
  postMinesEventLayout = $('.postMinesEventLayout');
  eventSwiper = undefined;
  timer = false;
  initFlag = true;
  swiperWrapper = $('.newPostList').find('.swiper-wrapper');
  swiperSlide = $('.newPostList').find('.swiper-slide');
  eventOptions = {
    loop: false,
    speed: 300,
    slidesPerView: 1.04,
    spaceBetween: '4.5%',
    effect: 'slide',
    normalizeSlideIndex: true,
    navigation: {
      nextEl: '.maineEventNext',
      prevEl: '.maineEventPrev',
    },
  };

  //スワイプ実行処理
  function initEventSwiper() {
    swiperSwitch = true;
    setTimeout(function () {
      eventSwiper = new Swiper('#eventSlide', eventOptions);
    }, 100);
  }

  //高さ調整
  var maxHeight1, maxHeight2, maxHeight3, setResHei1, jsCardHei, jsSpHei, jsPostHei;
  var jsAllset;
  jsPostHei = $('p.jsPostHei');
  jsSpHei = $('.jsSpHei');
  jsCardHei = $('.jsCardHei');
  jsAllset = $('.jsAllset');

  function initEventHeiFunc() {
    responsibleCheck = $('#responsibleCheck').width();
    jsPostHei.removeAttr('style');
    if (responsibleCheck == 2) {
      maxHeight1 = 0;
      maxHeight2 = 0;
      maxHeight3 = 0;

      jsAllset.height('auto');
      jsAllset.find('h3').height('auto');
      jsAllset.find('p.postDetailTxt').height('auto');

      jsAllset.each(function () {
        if ($(this).outerHeight() > maxHeight1) {
          maxHeight1 = $(this).outerHeight();
        }
        if ($(this).find('p.postDetailTxt').outerHeight() > maxHeight2) {
          maxHeight2 = $(this).find('p.postDetailTxt').outerHeight();
        }
        if ($(this).find('h3').outerHeight() > maxHeight3) {
          maxHeight3 = $(this).find('h3').outerHeight();
        }
      });
      jsAllset.height(maxHeight1).find('p.postDetailTxt').height(maxHeight2);
      jsAllset.find('h3').height(maxHeight3);
    } else {
      jsAllset.height('auto');
      jsAllset.find('p.postDetailTxt').height('auto');
      jsAllset.find('h3').height('auto');
      maxHeight3 = 0;

      jsAllset.each(function () {
        if ($(this).find('h3').outerHeight() > maxHeight3) {
          maxHeight3 = $(this).find('h3').outerHeight();
        }
      });
      jsAllset.find('h3').height(maxHeight3);
    }
  }

  //スワイプ終了処理
  function resetEventSwiper() {
    swiperSwitch = false;
    setTimeout(function () {
      for (var i = 0; i < swiperWrapper.length; i++) {
        swiperWrapper[i].removeAttribute('style');
      }
      for (var i = 0; i < swiperSlide.length; i++) {
        swiperSlide[i].removeAttribute('style');
      }
      eventSwiper.destroy(true, true);
      eventSwiper = undefined;
    }, 300);
  }

  //初回読み込み時
  responsibleCheck = $('#responsibleCheck').width();
  if (responsibleCheck == 2) {
    initEventSwiper();
    setTimeout(initEventHeiFunc, 400);
  } else {
    swiperSwitch = false;
  }

  //リサイズ処理
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      //リサイズ終了後の処理
      setTimeout(initEventHeiFunc, 400);
      responsibleCheck = $('#responsibleCheck').width();
      if (responsibleCheck == 2 && swiperSwitch == false) {
        initEventSwiper();

      } else if (responsibleCheck == 1 && swiperSwitch == true) {
        resetEventSwiper();
      }
    }, 200);
  });
}