import {
  resizeFuncCheck,
  resizeFunc,
  resizecheck
} from '../common/_resizecheck';
export function contentAdjustFunc() {
  var varHeader, headerHeight, varMain, headPtJs, resizecheck, setScr;
  resizecheck = $('#responsibleCheck').width();
  headPtJs = $('#headPtJs');
  varHeader = $('#header');
  varMain = $('main');
  headerHeight = varHeader.outerHeight(true);
  varMain.css('padding-top', headerHeight);
  if (resizecheck == '2') {
    headPtJs.css('padding-top', headerHeight);
  } else {
    headPtJs.removeAttr('style');
  }
}

export function resetStyleFunc() {
  var resizecheck, scrollposReset;
  resizecheck = $('#responsibleCheck').width();
  if (resizecheck != '2') {
    //pcの処理
    $('#headJs').removeAttr('style').removeClass('open');
    $('#jsMBtn').removeClass('active');
    $('.fNavColumn').find('dl dd').removeAttr('style');
  }
}

//電話リンクを無効化
export function telModalFunc() {
  let telModal, telScrollpos, modalClose;

  telModal = $('#telModalLayout');
  modalClose = $('.telOverlay,.closeBtn');
  $('a[href^="tel:"]').on('click', function (e) {
    if ($(this).hasClass('notTel')) {
      e.preventDefault();
    } else {
      e.preventDefault();
      telModal.fadeIn(1200);
      telScrollpos = $(window).scrollTop();
      $('body').addClass('fixedBody').css({
        'top': -telScrollpos
      });
    }
  });

  modalClose.on('click', function () {
    telModal.fadeOut(1200);
    $('body').removeClass('fixedBody').css({
      'top': 0
    });
    window.scrollTo(0, telScrollpos);
  });
}