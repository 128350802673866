export function aboutQaFunc() {
  var qaListQues, btnAllClose, setThis, setStatus;
  btnAllClose = $('.btnAllClose');
  qaListQues = $('.qaListQues');
  qaListQues.append('<span class="qaListIcon"></span>');

  //全て開くをクリックした場合
  btnAllClose.on('click', function () {
    setThis = $(this).parent().next().find('dl.qaList');
    setStatus = $(this).text();
    if (setStatus === '全て開く') {
      $(this).text('全て閉じる');
      setThis.find('dt.qaListQues').addClass('isOpen');
      setThis.find('dd').slideDown();
    } else if (setStatus === '全て閉じる') {
      $(this).text('全て開く');
      setThis.find('dt.qaListQues').removeClass('isOpen');
      setThis.find('dd').slideUp();
    }
  });

  //Qをクリックした場合
  qaListQues.on('click', function () {
    if ($(this).next().css('display') == 'none') {
      $(this).addClass('isOpen').next().slideDown();
    } else {
      $(this).removeClass('isOpen').next().slideUp();
    }
  });

}