/* ----------------------------------------------------------------------------------
透過ホバー
---------------------------------------------------------------------------------- */
export function hoverFunc() {
    // notHoverクラスは透明度の制御を解除
    $('a:not(.notHover)').hover(function () {
        $(this).stop().animate({
            opacity: '0.7'
        }, 300);

    }, function () {
        $(this).stop().animate({
            opacity: '1'
        }, 300);
    });
}

export function hoverOtherFunc() {
    $('.uaOther,.uaMorejs,.uaMoreOtherjs,.uaEntJs,.uaMovejs,.uaBannerJs').hover(function () {
        $(this).stop().animate({
            opacity: '0.8'
        }, 200);

    }, function () {
        $(this).stop().animate({
            opacity: '1'
        }, 200);
    });
}

//電話リンクを無効化
export function telLinkFunc() {
//    $('a[href^="tel:"]').css('cursor', 'default').on('click', function (e) {
//        e.preventDefault();
//    });
}

//UA判定でhoverAction制御
export function uaHoverFunc() {
    var uaHover;
    uaHover = $(".uaMovejs, .uaHeadNavjs,.uaOther,.uaMorejs,.uaMoreOtherjs,.uaEntJs,.uaSnsJs,.uaZoomjs,.uaMethodJs,.uaBannerJs,.uaNavJs");
    uaHover.each(function () {
        $(this).removeClass("uaMovejs uaHeadNavjs uaOther uaMorejs uaMoreOtherjs uaEntJs uaSnsJs uaZoomjs uaMethodJs uaBannerJs uaNavJs");
    });
}