/**
 *レスポンシブチェック
 *cssのwidth=2でSP以下を判断
 **/
export var resizeFunc, resizecheck;
export function resizeFuncCheck() {
  resizeFunc = function() {
    resizecheck = $('#responsibleCheck').width();
  };
}
