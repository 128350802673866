import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from './_resizecheck';
var header, setScr, setNum, headPtJs, flexScrJs, scrResizecheck;
header = $('#scrOffJs');
headPtJs = $('#headPtJs');
flexScrJs = $('#flexScrJs');
setNum = 100;

var jsMBtn, varGlobalNavH, getHeadH, btnFlag, scrollpos, navResizecheck;
var navList = $('ul.navList li p');
var dlSpMode = $('dl.SpMode');
export function headerFunc() {

    jsMBtn = $('#jsMBtn');
    jsMBtn.on('click', function () {
        $(this).toggleClass('active');
        if (headPtJs.hasClass('open')) {
            $('body').removeClass('fixedBody').css({
                'top': 0
            });
            window.scrollTo(0, scrollpos);
            headPtJs.removeClass('open');
        } else {
            headPtJs.addClass('open');
            scrollpos = $(window).scrollTop();
            $('body').addClass('fixedBody').css({
                'top': -scrollpos
            });
        }
    });
    navList.on('click', function () {
        navResizecheck = $('#responsibleCheck').width();
        if (navResizecheck != 1) {
            if (!$(this).hasClass('move')) {
                $(this).toggleClass('open').parent().next().slideToggle();
                return false;
            }
        }
    });
}

//ヘッダースクロール処理
export function headerScrFunc() {
    scrResizecheck = $('#responsibleCheck').width();
    setScr = $(window).scrollTop();
    if (resizecheck != '2') {
        if (setScr > setNum) {
            header.addClass('scrOff');
            headPtJs.addClass('scrOn');
            flexScrJs.addClass('scrOn');
        } else {
            header.removeClass('scrOff');
            headPtJs.removeClass('scrOn');
            flexScrJs.removeClass('scrOn');
        }
    }
}